.introduce {
  color: var(--sub-font-color-2);
  padding-top: 75px;
}
.introduce h1 {
  font-size: 4rem;
}

.introduce article {
  line-height: 1.8;
  letter-spacing: 2px;
}

.introduce button {
  border: none;
  border-radius: 3px;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 35%, rgba(255,184,0,1) 100%);
  color: white;
  font-size: 1.4rem;
  padding: 10px 30px;
  transition: filter 0.5s linear;
  margin-bottom: 7rem!important;
}

.introduce button:hover {
  filter: brightness(1.3);
  filter: drop-shadow(0 0 var(--shadow-px) var(--shadow-color));
  transition: filter 0.5s linear;
}


.portfolio {
  padding-top: 180px;
}
.portfolio a {
  color: var(--main-font-color);
  text-decoration: none;
}

.portfolio h1 {
  color: var(--sub-font-color-2);
}

.card-left {
  position: relative;
  left: -100%;
  transition: all 1.5s ease;
}
.card-right {
  position: relative;
  right: -100%;
  transition: all 1.5s ease;
}

@media (max-width:360px) {
  .introduce h1 {
    font-size: 10vw;
  }
  .navbar-brand {
    font-size: 6vw!important;
    margin: 0!important;
  }
}

footer a, footer a:focus, footer span{
  color: var(--main-font-color);
  font-weight: 600;
}
footer a:hover, footer a.active {
  color:  var(--sub-font-color-1);
}

