@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
:root {
  --main-bg-color : #8b1414;
  --sub-bg-color-1 : #03071E;
  --sub-bg-color-2: #2B2D42;
  --main-font-color: #edf2f4;
  --sub-font-color-1: #04d9b2;
  --sub-font-color-2: #edf2f4;
  --fixed-font-color: #edf2f4;
  --shadow-color: #ffb800;
  --shadow-px: 3px;
}
*{
  max-width: 100%!important;
}
html {
  scroll-behavior: smooth;
}
body {
  background: var(--main-bg-color );
  font-family: 'Montserrat', sans-serif;
  color: var(--fixed-font-color);
  font-size: 20px;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
a {cursor: pointer;}
h1, h2, h3, h4, h5, h6 {font-weight: bold;}
section {margin: auto;position: relative;}
section .container {position: relative;z-index: 1;}
section svg {fill: var(--main-bg-color)}
section .svg-top {position: absolute; top: 0;}
section .svg-bottom {position: absolute;bottom: -20px;}
.ml-auto {margin-left: auto!important;}
.main-bg { background: var(--main-bg-color); }
.bg-sub-1 { background: var(--sub-bg-color-1); }
.bg-sub-2 { background: var(--sub-bg-color-2); }
.navbar-bg {
  background-color: var(--sub-bg-color-2);
  box-shadow: 1px 1px 10px black;
  opacity: 0.95;
}
.navbar-bg a, .navbar a:focus {
  color: var(--main-font-color)!important;
}
.navbar a, .navbar a:focus, .navbar .nav-item{
  color: var(--sub-font-color-2);
  font-weight: 600;
  text-decoration: none;
}
.navbar a:hover, footer a:hover, .navbar a.active {
  color:  var(--sub-font-color-1)!important;
}

.rocket {
  color: var(--sub-font-color-2);
  transform: rotate(270deg);
  position: fixed;
  right: 0;
  bottom: -50vh;
  z-index: 100;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  width: 3em;
  height: 2em;
  overflow: visible;
  vertical-align: -0.125em;
  
}

.rocket path {
  cursor: pointer;
}
.rocket path:hover, .toggleMode:hover {
  opacity: 0.9;
}

.toggleMode {
  position: fixed;
  left: 10px;
  bottom: 25px;
  z-index: 100;
  cursor: pointer;
  font-size: 35px;
  user-select: none;
  color: var(--sub-font-color-2);
}
@keyframes rocketInfinite {
  from {bottom: 15px;}
  to{bottom: 25px;}
}
@keyframes rocketWelcome {
  from {bottom: -50px}
  to{bottom: 15px;}
}

@keyframes rocketBye {
  from {bottom: 25px;}
  to{bottom: 120vh;}
}

.progress {
  background-color: var(--sub-bg-color-1);
  height: 0.6rem;
}
.progress-bar {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,9,9,1) 35%, rgba(255,184,0,1) 100%);
}
.progress, .progress-bar {
  overflow: visible!important;
  position: relative;
  border-radius: 0.4rem;
}
.percent-label {
  position: absolute;
  right: 0;
  top: -14px;
  opacity: 0;
}

.card {
  background: var(--sub-bg-color-2);
  box-shadow: 4px 2px 16px #000;
  cursor: pointer;
}

.card:hover {
  color: var(--sub-font-color-1);
  transition: color 0.3s ease-in-out;
}

.card:hover img {
  transition: transform 0.5s ease-in-out;
  transform: scale(1.1);
}
